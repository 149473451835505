import { FC, HTMLProps } from "react";
import styles from "./DetailBtn.module.scss";
import classNames from "classnames";
import Arrow from "./arrow.svg";

export type Props = {
  title: string;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const DetailBtn: FC<Props> = (props) => {
  const { addclasses, title } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  return (
    <div className={className} {...props}>
      {title}
      <Arrow className={styles["arrow"]} />
    </div>
  );
};

export default DetailBtn;
