import { FC, HTMLProps } from "react";
import styles from "./SectionHeader.module.scss";
import classNames from "classnames";
import SectionNameBtn from "../Buttons/SectionNameBtn/SectionNameBtn";
import { useRouter } from "next/router";
import GridContainer from "../GridContainer/GridContainer";

export type Props = {
  buttonName: string;
  description: string;
  href: string;
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const SectionHeader: FC<Props> = (props) => {
  const { addclasses, href, description, buttonName } = props;
  const router = useRouter();
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const handleClick = (e: any) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(href, "_blank");
    } else {
      router.push(href);
    }
  };

  return (
    <GridContainer addclasses={[className]}>
      <SectionNameBtn
        title={buttonName}
        onClick={handleClick}
        addclasses={[styles["btn"]]}
      />
      <h2 className={styles["desc"]}>{description}</h2>
    </GridContainer>
  );
};

export default SectionHeader;
