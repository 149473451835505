import {
  MouseEventHandler,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import Image, { StaticImageData } from "next/image";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import { products } from "../assets/data/products";
import { solutions } from "../assets/data/solutions";
import About from "../components/About";
import BigBlueBtn from "../components/Buttons/BigBlueBtn";
import MainCard from "../components/Cards/MainCard/MainCard";
import CustomHead from "../components/CustomHead";
import Discuss from "../components/Discuss";
import Header from "../components/Header";
import Map from "../components/Map";
import MaxWidth from "../components/MaxWidth";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import SectionHeader from "../components/SectionHeader";

import discussStyles from "../components/Discuss/Discuss.module.scss";
import mapStyles from "../components/Map/Map.module.scss";
import styles from "../styles/Home.module.scss";
import { useRouter } from "next/router";

const createAnimation = (to: string, screenPosition: string = "70%") => {
  const params = [
    to,
    { opacity: 0, y: 50 },
    {
      opacity: 1,
      y: 0,
      scrollTrigger: ScrollTrigger.create({
        trigger: to,
        start: "top " + screenPosition,
        markers: false,
      }),
    },
  ];
  return params as [string, {}, {}];
};

export default function Home() {
  const root = useRef(null);
  const router = useRouter();
  const videoRef = useRef<HTMLVideoElement>(null);
  gsap.registerPlugin(ScrollTrigger);
  const { t } = useTranslation([
    "common",
    "mainPage",
    "products",
    "solutions",
    "meta",
  ]);

  const goTo: (e: any, href: string) => void = (e, href) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(href, "_blank");
    } else {
      router.push(href);
    }
  };

  useLayoutEffect(() => {
    const ctx = gsap.context((self) => {
      gsap.fromTo(...createAnimation(`.${styles["products__header"]}`));
      const products = (self as any).selector(`.products__card`);
      products.forEach((product: any) => {
        gsap.fromTo(...createAnimation(product));
      });

      gsap.fromTo(...createAnimation(`.${styles["products__btn"]}`));
      gsap.fromTo(...createAnimation(`.${styles["solutions__header"]}`));
      const solutions = (self as any).selector(`.solutions__card`);
      solutions.forEach((solution: any) => {
        gsap.fromTo(...createAnimation(solution));
      });
      gsap.fromTo(...createAnimation(`.${styles["solutions__btn"]}`));
      // gsap.fromTo(...createAnimation(`.news_section-title`));
      // const news = (self as any).selector(`.${styles["news"]} > div`);
      // news.forEach((single_news: any) => {
      //   gsap.fromTo(...createAnimation(single_news));
      // });
      gsap.fromTo(...createAnimation(`.${mapStyles["section-description"]}`));
      gsap.fromTo(...createAnimation(`.${mapStyles["btn"]}`));
      gsap.fromTo(...createAnimation(`.${mapStyles["map-container"]}`));
      gsap.fromTo(...createAnimation(`.${discussStyles["container"]}`));
    }, root);
    return () => ctx.kill();
  }, []);

  useEffect(() => {
    const { innerWidth } = window;
    const webm_src: any = document.getElementById("video_source_webm");
    const mp4_src: any = document.getElementById("video_source_mp4");
    const video = document.getElementById("header_video") as HTMLVideoElement;
    if (innerWidth > 700) {
      webm_src.src = "/videos/video_1.webm";
      mp4_src.src = "/videos/video_1.mp4";
    } else {
      webm_src.src = "/videos/video_1_mobile.webm";
      mp4_src.src = "/videos/video_1_mobile.mp4";
    }
    video.load();
    video.play().catch((e) => console.log(e));
  }, []);

  return (
    <PageWrapper>
      <CustomHead
        metaTitle={t("meta:main_page-title")!}
        desc={t("meta:main_page")!}
      >
        <meta name="yandex-verification" content="e4d74f27dded5002" />
      </CustomHead>
      <div ref={root}>
        <Header />
        <video
          id="header_video"
          ref={videoRef}
          className={`${styles["video_header"]}`}
          poster="/imgs/video_preview.jpg"
          playsInline
          muted
          autoPlay
          loop
        >
          <source id="video_source_webm" type="video/webm" />
          <source id="video_source_mp4" type="video/mp4" />
        </video>

        <MaxWidth addclasses={[styles["products"]]}>
          <SectionHeader
            buttonName={t("products")}
            href="/product"
            description={t("mainPage:product_section")}
            addclasses={[styles["products__header"]]}
          />
          {products.slice(0, 3).map((product) => {
            return (
              <MainCard
                title={t(`products:name.${product.href}`)}
                description={t(`products:description.${product.href}`)}
                imgSrc={product.previewImg}
                href={`/product/${product.href}`}
                key={product.id}
                addclasses={["products__card"]}
              />
            );
          })}
          <BigBlueBtn
            title={t("all_products")}
            onClick={(e) => goTo(e, "/product")}
            addclasses={[styles["products__btn"]]}
          />
        </MaxWidth>
        <About />
        <MaxWidth addclasses={[styles["solutions"]]}>
          <SectionHeader
            buttonName={t("common:solutions")}
            href="/solution"
            description={t("mainPage:solutions_applied")}
            addclasses={[styles["solutions__header"]]}
          />
          {solutions.slice(0, 3).map((solution) => {
            return (
              <MainCard
                title={t(`solutions:name.${solution.href}`)}
                description={t(`solutions:description-card.${solution.href}`)}
                imgSrc={solution.previewImg}
                href={`/solution/${solution.href}`}
                key={solution.id}
                addclasses={["solutions__card"]}
              />
            );
          })}
          <BigBlueBtn
            title={t("common:all_solutions")}
            onClick={(e) => goTo(e, "/solution")}
            addclasses={[styles["solutions__btn"]]}
          />
        </MaxWidth>
        <Map />
        <Discuss addclasses={[styles["discuss"]]} />
      </div>
    </PageWrapper>
  );
}

export async function getStaticProps({ locale }: { locale: any }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || "en", [
        "common",
        "products",
        "mainPage",
        "solutions",
        "map",
        "meta",
      ])),
    },
  };
}
