import { FC, HTMLProps } from "react";
import styles from "./Header.module.scss";
import classNames from "classnames";
import GridContainer from "../GridContainer/GridContainer";
import { useRouter } from "next/router";
import BigBlueBtn from "../Buttons/BigBlueBtn";
import { useTranslation } from "next-i18next";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const Header: FC<Props> = (props) => {
  const { addclasses } = props;
  const router = useRouter();
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );
  const titleClass = classNames([styles["title"], "H1"]);
  const { t } = useTranslation(["common", "mainPage"]);

  const goTo: (e: any, href: string) => void = (e, href) => {
    if (e.ctrlKey || e.metaKey) {
      window.open(href, "_blank");
    } else {
      router.push(href);
    }
  };

  return (
    <div className={styles["wrapper"]}>
      <GridContainer addclasses={[className]}>
        <h1 className={titleClass}>{t("mainPage:header")}</h1>
        <BigBlueBtn
          onClick={(e) => goTo(e, "/solution")}
          title={t("common:solutions")}
          addclasses={[styles["solutions"]]}
        />
      </GridContainer>
    </div>
  );
};

export default Header;
