import { FC, HTMLProps, useLayoutEffect, useRef } from "react";
import styles from "./About.module.scss";
import classNames from "classnames";
import BackgroundImg from "../../public/imgs/02-Big-img.jpg";
import Image from "next/image";
import OutlinedBtn from "../Buttons/OutlinedButton";
import { useTranslation } from "next-i18next";
import GridContainer from "../GridContainer/GridContainer";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/dist/ScrollTrigger";

export type Props = { addclasses?: string[] } & HTMLProps<HTMLDivElement>;

const About: FC<Props> = (props) => {
  gsap.registerPlugin(ScrollTrigger);
  const root = useRef(null);
  const { addclasses } = props;
  const { t } = useTranslation(["mainPage", "common"]);
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.fromTo(
        `.${styles["title"]}`,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: ScrollTrigger.create({
            trigger: root.current,
            start: "top 60%",
          }),
        }
      );
    }, root);
    return () => ctx.kill();
  }, []);

  return (
    <div ref={root} className={styles["wrapper"]}>
      <Image
        src={BackgroundImg}
        alt=""
        quality={100}
        sizes="100vw"
        className={styles["image"]}
      />
      <GridContainer addclasses={[className]}>
        <h1 className={styles["title"]}>
          {
            <span>
              {t("about_1")}
              <br />
              {t("about_2")}
              <br />
              {t("about_3")}
            </span>
          }
        </h1>
        <OutlinedBtn
          title={t("common:about_us")}
          href="/about"
          addclasses={[styles["btn"]]}
          light
        />
      </GridContainer>
    </div>
  );
};

export default About;
